import React from "react"

import { footnote, footlink } from "../shared/links"
import { ul } from "../shared/structure"

import styles from "../../styles/main.module.css"

export default function TheImportanceOfBeingEarnest() {

  const footnotes = [
      "Somehow, my family kept coming back to this movie. We watched it when I was a kid at least once, followed by once recently during the pandemic, and I convinced my teacher to show it to our 10th grade english class a little while back.",
      "From the final scene in the final act (final page, as there is technically only one scene per act).",
      "The camera is looking at the book, but pans away the second Jack arrives at the general’s line. From this we are led to believe there is something he’s hiding.",
  ]

  const singleQuotes = [
    "All women become like their mothers. That is their tragedy. No man does, and that is his.",
    "The good ended happily, and the bad unhappily. That is what Fiction means.",
    "I really don't see anything romantic in proposing. It is very romantic to be in love. But there is nothing romantic about a definite proposal. Why, one may be accepted. One usually is, I believe. Then the excitement is all over. The very essence of romance is uncertainty. If ever I get married, I'll certainly try to forget the fact.",
    "In matters of grave importance, style, not sincerity, is the vital thing.",
    "Oh! I don't think I would like to catch a sensible man. I shouldn't know what to talk to him about.",
    "I do not approve of anything that tampers with natural ignorance. Ignorance is like a delicate exotic fruit; touch it and the bloom is gone. The whole theory of modern education is radically unsound. Fortunately in England, at any rate, education produces no effect whatsoever.",
    "Ever since I met you I have admired you more than any girl...I have ever met since...I met you.",
    "It is awfully hard work doing nothing. However, I don’t mind hard work where there is no definite object of any kind.",
    "Ernest, we may never be married. From the expression on mamma’s face I fear we never shall. Few parents now-a-days pay any regard to what their children say to them. The old-fashioned respect for the young is fast dying out. Whatever influence I ever had over mamma, I lost at the age of three. But although she may prevent us from becoming man and wife, and I may marry someone else, and marry often, nothing that she can possibly do can alter my eternal devotion to you.",
    "To lose one parent, Mr. Worthing, may be regarded as a misfortune; to lose both looks like carelessness.",
    "In married life, three is company and two is none.",
    "I hope you have not been leading a double life, pretending to be wicked and being really good all the time. That would be hypocrisy.",
    "I have often spoken to the poorer classes on the subject. But they don’t seem to know what thrift is.",
    "I never travel without my diary. One should always have something sensational to read in the train.",
    "The truth is rarely pure and never simple.",
    "If I am occasionally a little over-dressed, I make up for it by being always immensely over-educated.",
    "It is always painful to part from people whom one has known for a very brief space of time. The absence of old friends one can endure with equanimity. But even a momentary separation from anyone to whom one has just been introduced is almost unbearable.",
    "A man who is much akled about is always very attractive. One feels there must be something in him after all.",
    "It would hardly have been a really serious engagement if it hadn’t been broken off at least once.",
    "Outside the family circle, pappa, I am glad to say, is entirely unknown. I think that is quite as it should be. The home seems to me to be the proper sphere for the man. And certainly once a man begins to neglect his domestic duties he becomes painfully effeminate, does he not?",
    "Hesitation of any kind is a sign of mental decay in the young, of physical weakness in the old.",
    "Style depends on the way the chin is worn. They are worn very high, just at present.",
    "Never speak disrespectfully of Society, Algernon. Only people who can’t get into it do that.",
    "I do not know whether there is anything peculiarly exciting in the air of this particular part of Hertfordshire, but the number of engagements that go on seems to me considerably above the proper average that statistics have laid down for our guidance.",
    "London society is full of women of the very highest birth who have, of their own free choice, remained thirty-five for years.",
    "Passionate celibacy is all that any of us can look forward to.",
    "I never change, except in my affections.",
    "The General was essentially a man of peace, except in his domestic life.",
    "It is a terrible thing for a man to find out suddenly that all his life he has been speaking nothing but the truth.",
  ].map((quote) => {
    return <p>"{quote}"</p>
  })

  const doubleQuotes = [
    [
      "I hope to-morrow is a fine day, Lane.",
      "It never is, sir.",
      "Lane, you’re a perfect pessimist.",
      "I do my best to give satisfaction, sir.",
    ],
    [
      "You’re quite perfect, Miss Fairfax.",
      "Oh! I hope I am not that. It would leave no room for developments, and I intend to develop in many directions.",
    ],
    [
      "Dead!",
      "Your brother Ernest dead?",
      "Quite dead.",
      "What a lesson for him! I trust he will profit by it.",
      "...",
      "Was the cause of death mentioned?",
      "A severe chill, it seems.",
      "... [Ernest Returns]",
      "After we had all been resigned to his loss, his sudden return seems to me peculiarly distressing.",
    ],
    [
      "You are continually christening, aren’t you?",
      "...",
      "The sprinkling, and, indeed, immersion of adults is a perfectly canonical practice."
    ],
    [
      "My dear Aunt Augusta, I mean he was found out! The doctors found out that Bunbury could not live, that is what I mean—so Bunbury died.",
      "He seems to have great confidence in the opinion of his physicians. I am glad."
    ],
  ].map((quotes) => {
    return quotes.map(quote => {
      return <p>"{quote}"</p>
    })
  })

  return (
    <React.Fragment>
      <h2>One of The Best Comedies Ever</h2>
      <hr className={styles.top_seperator} />
      <p>A true classic, <i>The Importance of Being Earnest</i> never disappoints. This was my first reading, but I’d already seen the 2002 rendition with Colin Firth at least three times throughout my life {footlink(1)}. Having already seen the same interpretation, it was hard not to imagine something similar to it while reading Wilde’s work, but reading does have some advantages. Despite being incredibly short, the jokes are packed so densely into the lines, that reading makes it easier to catch them than watching does. It’s also clear that the 2002 rendition adds a lot (primarily to the setting: i.e. the nightclub).</p>
      <p>Also, I’ve begun to suspect that old lord Bracknell is  a potential bunbury character. I’d never thought of this before, but the way lady Bracknell uses him to justify her decisions is reminiscent of Jack’s Ernest and Algy’s Bunbury, especially given that old lord Bracknell is always in bad health and never to be seen. Beyond that, from the reading it’s not obvious at all that Jack’s claim that his father’s name was Ernest might be false {footlink(2)}. In the rendition Firth (playing Jack) pauses a little then closes the book <i>before</i> saying that the general’s name was Ernest {footlink(3)}. He then delivers the line slowly, as if he were not being entirely truthful. Personally, I think that interpretation is actually better than the straightforward one of his name <i>actually being</i> Ernest. They lie throughout the entire storyline, and most characters’ supposed virtues are actually built on lies, so it makes sense for the virtue of being Ernest to also be of that nature.</p>
      <p>Anyways, this is such a short work that you could read it in an hour, and I guarantee that you’ll have a fun time. This is definitely a must-read (or watch) if you’ve never seen it and enjoy humor that isn’t necessarily explicitly exploding in-your-face (i.e. deep-fried memes). I have not read Oscar Wilde’s other writings, but I think I’ll definitely explore them after this. I guess now I’ll leave you with some quotes (to use in your life, of course).</p>
      {ul(singleQuotes)}
      {ul(doubleQuotes)}
      {footnotes.map((note, index) => footnote(index + 1, note))}
      <div className={styles.phone_home}>
        <a href="/">Click me to return home.</a>
      </div>
    </React.Fragment>
  )
}